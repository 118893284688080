<template>
  <div class="change-password-view container">
    <tab-component :tabs="tabs">
      <template>
        <h1>
          {{ changeOwnPassword ? 'Skift password' : `Skift password for ${this.appUser.name}` }}
        </h1>
      </template>
    </tab-component>

    <section>
      <section-header title="Password">
        <template>
          <b-button @click="changePassword"
                    type="is-primary"
                    :loading="loading">
            Skift password
          </b-button>
        </template>
      </section-header>
    </section>

    <div class="container">
      <div class="columns">
        <div class="column" v-if="changeOwnPassword">
          <b-field label="Gammelt password" class="column">
            <b-input type="password"
                     v-model="formChangePassword.oldPassword"
                     password-reveal></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Nyt password" class="column">
            <b-input type="password"
                     v-model="formChangePassword.newPassword"
                     password-reveal></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Gentag nyt password" class="column">
            <b-input type="password"
                     v-model="formChangePassword.newPasswordRepeat"
                     password-reveal></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApiState from '../enums/APISTATE';

export default {
  name: 'change-password-view',
  props: {
    userId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    formChangePassword: {},
  }),
  async mounted() {
    try {
      await this.loadMyUser();
      if (this.userId !== null) {
        await this.loadAppUser(this.userId);
      }
    }
    catch (error) {
      console.error(error);
    }
  },
  methods: {
    ...mapActions('appUsers', [
      'loadAppUser',
      'loadMyUser',
      'setAppUserPassword'
    ]),
    async changePassword() {
      if (this.changeOwnPassword) {
        await this.setAppUserPassword({
          id: this.myAppUser.id,
          form: this.formChangePassword
        });
      }
      else {
        await this.setAppUserPassword({
          id: this.userId,
          form: this.formChangePassword
        });
      }
      if (this.appUserApiState !== ApiState.ERROR) {
        this.goBack();
      }
      else {
        // show error?
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters('appUsers', [
      'appUser',
      'myAppUser',
      'appUserApiState'
    ]),
    changeOwnPassword() {
      return this.userId === null;
    },
    loading() {
      return this.appUserApiState === ApiState.LOADING;
    },
    tabs() {
      if (this.changeOwnPassword) {
        return [
          { title: 'Password', to: '/user/password' }
        ];
      }
      return [
        { title: 'Stamdata', to: `/users/${this.userId}/edit` },
        { title: 'Password', to: `/users/${this.userId}/password` }
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
.change-password-view {
  .form-controls {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

  }
}
</style>